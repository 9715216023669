﻿@import '../assets/scss/_master.scss';

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  font-family: 'Lato', sans-serif;
  font-size: $content2;
  background-color: $color5;
  color: $color1;

  * {
    &:focus {
      outline: 2px solid black;
    }
    // :focus-visible makes it so outline only shows on keyboard focus
    // however some browsers do not support this so need this workaround for them
    &:focus:not(:focus-visible) {
      outline: none;
    }
  }

  .icon svg {
    width: 100%;
    height: 100%;
  }

  .btn {
    cursor: pointer;
    @include user-select(none);
  }

  input,
  button,
  select,
  mat-select,
  textarea {
    border: none;
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;

    &.invalid {
      border: 2px solid $errorText;
    }
  }

  button {
    background: none;
    cursor: pointer;
    @include user-select(none);
  }

  base-layout {
    @include animation(fadeIn 150ms linear);
  }

  .banner {
    padding: 20px;
    @include box-sizing(border-box);
    @include absolute(bottom 0 left 0 right 0);
    background-color: $success;
    color: $color8;
    text-align: center;
    font-size: $content1;
    height: 60px;
    z-index: 5;

    &.error {
      background-color: $errorText;
    }

    &.permanent {
      z-index: 4;
      background-color: #f0b34bb0;
      pointer-events: none;
    }

    .link {
      color: blue;
      cursor: pointer;
      text-decoration: underline;
      pointer-events: all;
    }
  }

  .loading-bars {
    @include animation(fadeIn 150ms linear);
  }

  .toggle-container {
    white-space: nowrap;

    .toggle,
    .label {
      display: inline-block;
      vertical-align: middle;
    }

    .toggle {
      width: 34px;
      height: 15px;
      border-radius: 15px;
      background-color: $color8;
      @include box-shadow(1px 1px 5px $color3);

      .dot {
        width: 17px;
        height: 17px;
        background-color: $color2;
        border: 2px solid $color8;
        border-radius: 50%;
        @include box-sizing(border-box);
        @include transform(translate3d(0, -1px, 0));
        @include transition(all 200ms linear);
      }

      &.active {
        background-color: $color4;

        .dot {
          background-color: $color4;
          @include transform(translate3d(100%, -1px, 0));
        }
      }
    }
  }
}

// Animations
@include keyframes(pulse) {
  30% {
    @include transform(scale(0.3));
  }

  100% {
    @include transform(scale(1));
  }
}

@include keyframes(rotator) {
  0% {
    @include transform(rotate(0) translateZ(0));
  }

  100% {
    @include transform(rotate(360deg) translateZ(0));
  }
}

@include keyframes(fadeOut) {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@include keyframes(fadeIn) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include keyframes(slideInLeft) {
  0% {
    @include transform(translateX(-100%));
  }

  100% {
    @include transform(translateX(0));
  }
}

@include keyframes(slideInLeftAndFade) {
  0% {
    @include transform(translateX(-100%));
    opacity: 0;
  }

  100% {
    @include transform(translateX(0));
    opacity: 1;
  }
}

@include keyframes(slideInBottom) {
  0% {
    @include transform(translateY(100%));
  }

  100% {
    @include transform(translateY(0));
  }
}

@keyframes dotanim {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  90% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}
