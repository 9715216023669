// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: #2888ca, secondary: #bcbcbc, tertiary: #a5a5a5, neutral: #a5a5a5
$_palettes: (
  primary: (
    0: #000000,
    10: #001d32,
    20: #003352,
    25: #003f64,
    30: #004a75,
    35: #005787,
    40: #00639a,
    50: #107dbe,
    60: #3d97da,
    70: #5db2f6,
    80: #95ccff,
    90: #cde5ff,
    95: #e8f2ff,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff
  ),
  secondary: (
    0: #000000,
    10: #001f24,
    20: #00363d,
    25: #00424a,
    30: #004f58,
    35: #005b66,
    40: #006874,
    50: #008391,
    60: #00a0b0,
    70: #22bccf,
    80: #4fd8eb,
    90: #97f0ff,
    95: #d0f8ff,
    98: #edfcff,
    99: #f6feff,
    100: #ffffff
  ),
  tertiary: (
    0: #000000,
    10: #001f24,
    20: #00363d,
    25: #00424a,
    30: #004f58,
    35: #005b66,
    40: #006874,
    50: #008391,
    60: #00a0b0,
    70: #22bccf,
    80: #4fd8eb,
    90: #97f0ff,
    95: #d0f8ff,
    98: #edfcff,
    99: #f6feff,
    100: #ffffff
  ),
  neutral: (
    0: #000000,
    4: #000c0e,
    6: #001316,
    10: #001f24,
    12: #002429,
    17: #002f35,
    20: #00363d,
    22: #003b42,
    24: #004047,
    25: #00424a,
    30: #004f58,
    35: #005b66,
    40: #006874,
    50: #008391,
    60: #00a0b0,
    70: #22bccf,
    80: #4fd8eb,
    87: #81e9f9,
    90: #97f0ff,
    92: #aef3ff,
    94: #c5f6ff,
    95: #d0f8ff,
    96: #daf9ff,
    98: #edfcff,
    99: #f6feff,
    100: #ffffff
  ),
  neutral-variant: (
    0: #000000,
    10: #171c22,
    20: #2c3137,
    25: #373c42,
    30: #42474e,
    35: #4e535a,
    40: #595f66,
    50: #72777f,
    60: #8c9198,
    70: #a6acb3,
    80: #c2c7cf,
    90: #dee3eb,
    95: #ecf1f9,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff
  )
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error)
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: $_primary,
      tertiary: $_tertiary
    )
  )
);

@include mat.core();

html {
  // Apply the light theme by default
  @include mat.core-theme($light-theme);
  @include mat.button-theme($light-theme);
}
