﻿@import '../assets/scss/_master.scss';
@import '@angular/material/prebuilt-themes/indigo-pink.css'; // needed to apply core styles for Angular Material comps

// as Dekin begins redesigning some of existing Converge pages and establishing site wide styles we need a place to declare those
// however we have to be careful not break any existing pages not yet redesigned... so adding here under the .v2 class to keep scoped

.main-section {
  .v2 {
    * {
      @include box-sizing(border-box);
    }

    .ml-hide {
      display: none !important;
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 0 43px;
      z-index: 4;

      & .no-padding {
        padding: 0;
      }

      &.with-back {
        padding: 0;
        justify-content: unset;

        .title-area {
          display: inline-flex;
          align-items: center;
        }
      }

      & > .auto-left {
        margin-left: auto;
      }

      .back-arrow {
        width: 50px;
        height: 100%;
        border-right: 2px solid $rowcolor4;
        margin-right: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          .icon svg path {
            fill: $color1;
          }
        }

        .icon {
          width: 20px;
          height: 20px;
          margin: 0;

          svg path {
            fill: $color3;
            @include transition(fill 175ms ease-in);
          }
        }
      }

      .anchor-container {
        display: inline-block;
        height: 100%;
        position: relative;

        &:hover {
          .anchor-options {
            display: block;
          }
        }

        .top-btn {
          &.anchor {
            display: flex;
          }

          .label {
            padding-right: 16px;
          }
        }

        .anchor-options {
          border-bottom: 2px solid $rowcolor4;
          display: none;
          @include animation(delayedSlideDown 200ms ease-out);
          transform-origin: 0 0;
          @include absolute(top 100% right 0);

          .top-btn {
            padding: 7px 0;
            border-top: 2px solid $rowcolor4;
            width: 100%;
          }
        }
      }

      @include keyframes(delayedSlideDown) {
        0% {
          @include transform(translate3d(0, 0, 0) scaleY(0));
        }

        50% {
          @include transform(translate3d(0, 0, 0) scaleY(0));
        }

        100% {
          @include transform(translate3d(0, 0, 0) scaleY(1));
        }
      }

      .top-btn {
        cursor: pointer;
        @include user-select(none);
        text-align: left;
        @include transition(background-color 0.2s ease-in);
        @include box-sizing(border-box);
        color: $turtleGreen;
        font-size: $content1;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        height: 100%;
        border-left: 2px solid $rowcolor4;
        background-color: $color8;
        vertical-align: top;

        &.anchor {
          .label {
            padding-right: 16px;
            white-space: nowrap;
          }
        }

        &.collapsible {
          .label {
            white-space: nowrap;
            overflow: hidden;
            max-width: 0;
            visibility: hidden;
            padding-right: 0px;
            @include transition(all 500ms ease);
          }

          &:hover {
            .label {
              max-width: 250px;
              visibility: visible;
              padding-right: 10px;
            }
          }
        }

        &.standard-positive {
          color: $pacificBlue;

          &.anchor {
            &:hover {
              color: $color8;
              background-color: $pacificBlue;

              svg {
                path,
                polyline,
                polygon,
                rect,
                circle {
                  fill: $color8;
                }
              }
            }
          }

          &:hover,
          &.anchor,
          &.active {
            svg {
              path,
              polyline,
              polygon,
              rect,
              circle {
                fill: $pacificBlue;
              }
            }
          }
        }

        &.priority-positive {
          &.anchor {
            &:hover {
              color: $color8;
              background-color: $turtleGreen;

              svg {
                path,
                polyline,
                polygon {
                  fill: $color8;
                }
              }
            }
          }

          &:hover,
          &.anchor,
          &.active {
            svg {
              path,
              polyline,
              polygon {
                fill: $turtleGreen;
              }
            }
          }
        }

        &.standard-negative {
          color: $color1;

          &.anchor {
            svg {
              path,
              polyline,
              polygon {
                fill: $color1;
              }
            }
          }

          &:hover {
            svg {
              path,
              polyline,
              polygon,
              rect {
                fill: $color1;
              }
            }
          }
        }

        &.inactive,
        &.disabled {
          pointer-events: none;
          color: $color13;

          .icon svg {
            path,
            polygon {
              fill: $color13;
            }
          }
        }

        .icon {
          width: 20px;
          height: 20px;
          margin: 8px 16px;
          flex-shrink: 0;

          svg {
            path,
            polyline,
            polygon,
            rect {
              fill: $color3;
            }
          }
        }
      }
    }

    .bottom {
      overflow-y: scroll;
      @include styled-scrollbar();

      .title {
        font-size: $headline;
        font-weight: bold;
        margin: 0;
        margin-bottom: 10px;

        &.jumbo {
          font-size: 32px;
        }

        &.thin {
          font-weight: normal;
        }

        &.suffix-container {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          .label {
            display: inline-block;
          }

          .suffix-btn {
            margin-left: 8px;
            position: relative;

            &:hover {
              & > .icon {
                background-color: $turtleGreen;

                svg {
                  path {
                    fill: $color8;
                  }
                }
              }

              .info-box {
                display: block;
              }
            }

            & > .icon {
              border-radius: 50%;
              width: 30px;
              height: 30px;
              @include transition(all 200ms ease-in);
              border: 2px solid $turtleGreen;

              svg {
                path {
                  fill: $turtleGreen;
                }
              }
            }
          }

          .info-box {
            border-radius: 4px;
            background-color: rgba($turtleGreen, 0.8);
            @include absolute(top 20px left 20px);
            z-index: 2;
            color: $color8;
            padding: 20px;
            min-width: 250px;
            display: none;

            p,
            li {
              font-size: $content1;
              font-weight: normal;
            }
          }
        }
      }

      .sub-title {
        color: $color3;
        font-size: $content2;
        margin: 0;
      }

      .warning {
        color: $color4;
        padding-right: 5px;

        svg {
          path,
          rect {
            fill: $color4;
          }
        }
      }

      hr.dashed {
        border-color: $rowcolor4;
        border-style: dashed;
        border-width: 1.5px;
      }

      hr.bold {
        width: 100%;
        height: 3px;
        background-color: $color1;
        margin: 20px 0;
      }

      .solid-line {
        border-bottom: 2px solid $rowcolor4;
        margin-bottom: 30px;
        width: 100%;
      }

      form,
      .column-container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        .column {
          flex: 1 0 48%;
          margin: 0 10px;
        }
      }

      .flex-row {
        display: flex;
        flex-direction: row;

        &.align-bottom {
          align-items: flex-end;
        }

        &.align-center {
          align-items: center;
        }

        &.align-baseline {
          align-items: baseline;
        }

        &.align-top {
          align-items: flex-start;
        }

        &.allow-wrap {
          flex-wrap: wrap;
        }

        &.justify-between {
          justify-content: space-between;
        }

        &.justify-center {
          justify-content: center;
        }

        &.justify-start {
          justify-content: flex-start;
        }

        &.justify-end {
          justify-content: flex-end;
        }

        &.full-width {
          width: 100%;
        }

        .form-box.small {
          margin-left: 20px;

          &:first-child {
            margin: 0;
          }
        }
      }

      .push-right {
        margin-left: auto;
      }

      .flex-column {
        display: flex;
        flex-direction: column;

        &.align-center {
          align-items: center;
        }

        &.align-left {
          align-items: flex-start !important;
        }

        &.align-right {
          align-items: flex-end;
        }
      }

      .flex-grow-full {
        flex-grow: 1 !important;
      }

      .flex-grow-none {
        flex-grow: 0 !important;
      }

      .flex-shrink-none {
        flex-shrink: 0 !important;
      }

      .flex-space-between {
        justify-content: space-between !important;
      }

      .auto-width {
        width: auto !important;
      }

      .full-width {
        width: 100%;
      }

      .underline {
        text-decoration: underline;
      }

      .bold-underline {
        font-weight: bold;
        text-decoration: underline;
      }

      .form-box {
        margin-bottom: 30px;

        &.bordered {
          box-shadow: 0 4px 7px $color3;
          border-radius: 4px;

          & > .box-header {
            &.light {
              border: 2px solid $rowcolor4;
            }
          }

          & > .box-content {
            border: 1px solid $rowcolor4;
            border-top: none;
            @include border-bottom-radius(4px);
            padding: 25px 15px;
          }

          &.dashed {
            & > .box-content {
              border: 5px dashed $rowcolor4;
              margin-top: 10px;
            }
          }
        }

        &.small {
          display: inline-block;
          width: 50%;
          margin: 0;
        }

        &.jumbo {
          border-radius: 12px;

          & > .box-header {
            padding: 20px 25px;
            font-size: $sub-head2;
            font-weight: bold;
            @include border-top-radius(12px);

            .icon {
              width: 20px;
              height: 20px;
              margin: 0;
              margin-right: 10px;
            }
          }

          & > .box-content {
            padding: 25px;
            @include border-bottom-radius(12px);
          }
        }

        &.tabs-container {
          .box-header {
            padding: 0;
            overflow: hidden;
          }

          .tab {
            display: flex;
            align-items: center;
            padding: 12px 32px;
            background-color: $color8;
            color: rgba($color1, 0.6);
            @include user-select(none);
            cursor: pointer;
            border-right: 1px solid $color13;
            @include transition(all 150ms ease-in);
            font-size: 18px;

            &:hover {
              color: $color1;
            }

            &.active {
              background-color: $color5;
              color: $color1;
            }

            .icon {
              width: 24px;
              height: 24px;
            }
          }
        }

        &.display-only {
          &.table-fields {
            .row {
              margin-bottom: 16px;

              .field {
                width: 33%;
              }
            }
          }

          .box-content {
            .box-section {
              border: none;
              padding-bottom: 0;
            }

            .flex-row {
              .box-section {
                margin-bottom: 0;
              }
            }
          }

          .row {
            &.split-fields {
              .field {
                flex-grow: 0;
                margin: 0 4px 8px;
                border-right-width: 2px;

                &:first-child {
                  margin-left: 0;
                }
              }
            }

            &.two-columns {
              .field {
                width: 50%;
              }
            }

            .field {
              border: 2px solid $rowcolor4;
              border-right-width: 0;
              font-size: $content2;
              display: inline-flex;
              align-items: center;
              margin: 0;
              padding: 10px;

              &:last-child {
                border-right-width: 2px;
              }

              &.borderless {
                border: none;
              }

              .icon {
                width: 15px;
                height: 15px;
                margin-right: 8px;
                flex-shrink: 0;

                svg {
                  path,
                  rect {
                    fill: $color1;
                  }
                }
              }

              .label,
              label {
                font-weight: bold;
                margin: 0;
                white-space: nowrap;

                &:after {
                  content: ':';
                  margin-right: 8px;
                }
              }

              &.no-colon label::after {
                content: '';
              }
            }
          }
        }

        &.full-width {
          width: 100%;

          &.above-columns {
            margin: 0 10px 20px;
          }
        }

        .box-header {
          background-color: $color1;
          color: $color8;
          font-size: $content1;
          padding: 15px;
          @include border-top-radius(4px);
          display: flex;
          align-items: center;

          &.light {
            background-color: $color8;
            color: $color1;

            .icon {
              svg {
                path,
                polygon,
                circle {
                  fill: $color4;
                }
              }
            }
          }

          &.transparent {
            background-color: transparent;
            color: $color1;
            font-weight: bold;
            padding-left: 0;
            padding-top: 0;
            padding-right: 0;

            & > .icon {
              width: 20px;
              height: 20px;

              svg {
                path,
                polygon {
                  fill: $color3;
                }
              }
            }

            & > .btn {
              margin: 0 10px;

              .icon {
                svg {
                  path,
                  polygon {
                    fill: $color8;
                  }
                }
              }
            }
          }

          .btn {
            .icon {
              margin-left: 0;
            }
          }

          .icon {
            width: 16px;
            height: 16px;
            margin-left: 10px;

            svg path {
              fill: $color8;
            }
          }

          & > .icon:first-child {
            margin-right: 10px;
            margin-left: 0;
          }

          & > .icon:last-child {
            margin-left: 10px;
          }
        }

        .box-content {
          &.light {
            background-color: $color8;
          }

          .box-section {
            border-bottom: 2px dashed $rowcolor4;
            margin-bottom: 30px;
            padding-bottom: 30px;

            &:last-child,
            &:last-of-type {
              border: none;
              margin-bottom: 0;
              padding-bottom: 0;
            }

            &.no-border {
              border: none;
              padding-bottom: 0;
            }

            &.collapsible {
              &.open {
                .section-header {
                  & > .btn.quick {
                    background-color: $color4;

                    .icon {
                      @include transform(rotate(270deg) scale(-1));

                      svg path {
                        fill: $color8;
                      }
                    }
                  }
                }

                .section-content {
                  max-height: 5000px;
                  opacity: 1;
                  pointer-events: auto;
                }
              }

              & > .section-header {
                cursor: pointer;
                display: inline-flex;

                & > .btn.quick {
                  margin-right: 10px;
                  background-color: $color13;

                  &.disabled {
                    opacity: 0.3;
                  }

                  .icon {
                    @include transform(rotate(270deg) scale(1));
                    @include transition(transform 300ms ease-in-out);

                    svg {
                      path,
                      polygon {
                        fill: $color8;
                      }
                    }
                  }
                }

                .label.caps {
                  text-transform: uppercase;
                }
              }

              .section-content {
                max-height: 0;
                opacity: 0;
                @include transition(all 250ms ease-out);
                pointer-events: none;
              }
            }

            .section-header {
              font-size: $content1;
              margin: 0 0 15px;
              font-weight: 600;
              display: flex;
              align-items: center;

              &.even-margin {
                margin: 8px;
              }

              &.font-weight-normal {
                font-weight: normal;
              }

              label {
                font-weight: normal;
              }

              .field {
                margin-bottom: 0;
              }

              & > .icon {
                width: 16px;
                height: 16px;

                &.large {
                  width: 25px;
                  height: 25px;
                }

                &.priority-positive {
                  svg {
                    path,
                    rect {
                      fill: $turtleGreen;
                    }
                  }
                }

                svg {
                  path,
                  polygon {
                    fill: $color3;
                  }

                  &.white {
                    path {
                      fill: #fff;
                    }
                  }
                }
              }

              & > .icon:first-child {
                margin-right: 10px;
              }

              & > .icon:last-child {
                margin-left: 10px;
              }

              .tooltip {
                margin-left: 10px;
              }
            }

            .section-content {
              & + .btn {
                margin-left: 8px;
              }
            }
          }

          .dropzone {
            border: 5px dashed $rowcolor4;
            margin: 0 0 10px;
            border-radius: 0;

            &.drag-hover {
              .message {
                svg {
                  path,
                  rect,
                  polygon {
                    fill: $rowcolor2;
                  }
                }
              }
            }
          }

          .watermark {
            width: 85px;
            height: 85px;
            margin: auto;

            svg {
              width: 100%;
              height: 100%;

              path,
              rect,
              polygon,
              circle {
                fill: $color3;
              }
            }
          }

          .grid {
            width: 100%;
            height: 100%;
            overflow-y: auto;

            .grid-row {
              border-bottom: 2px solid $rowcolor4;
              display: flex;
              align-items: center;
              justify-content: space-between;
              @include animation(slideInLeft 250ms ease-out);

              &.dashed {
                border-bottom-style: dashed;

                .grid-cell {
                  border: none;
                }
              }

              .grid-cell {
                padding: 10px 8px;
                border-left: 2px solid $rowcolor4;
                display: flex;
                align-items: center;

                &:first-child {
                  border: none;
                }

                span {
                  display: inline-block;
                }

                .icon {
                  width: 15px;
                  height: 15px;
                  flex: 0 0 auto;

                  svg {
                    path {
                      fill: $color1;
                    }
                  }
                }

                .btn {
                  margin: 0;

                  .icon svg path {
                    fill: $color8;
                  }
                }

                .label {
                  margin-bottom: 0;
                  margin-left: 10px;
                }

                .trash {
                  cursor: pointer;
                }
              }
            }
          }
        }

        .row-header {
          margin-bottom: 10px;
          margin-left: 8px;
          display: block;
          font-size: $content1;
        }

        .row,
        .column {
          .field {
            @include animation(fadeIn 200ms ease-out);

            &.margin-bottom {
              margin-bottom: 8px;
            }

            &.small {
              width: 50px;
              flex-grow: 0;
            }

            &.medium {
              width: 100px;
              flex-grow: 0;
            }

            &:last-child {
              margin-right: 0;
            }

            &.no-col {
              padding-right: 1rem;
              padding-left: 1rem;
            }

            .btn .label {
              margin: 0;
            }

            .label,
            label {
              margin-bottom: 10px;
              display: block;
              font-size: $content1;

              &.emphasize {
                text-transform: uppercase;
                font-weight: bold;
                color: $color3;
              }

              &.bold {
                font-weight: bold;
              }

              .icon {
                width: 16px;
                height: 16px;
                margin-right: 5px;

                &.standard-positive {
                  svg path {
                    fill: $turtleGreen;
                  }
                }

                svg path {
                  fill: $color3;
                }
              }

              .sub-label {
                font-size: $content2;
                color: $color3;
              }
            }

            .input-container {
              display: flex;
              align-items: center;

              input {
                margin-right: 8px;
                min-width: 0;
              }
            }
            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            input,
            select,
            .mat-mdc-select-trigger,
            textarea,
            div[contenteditable='true'] {
              padding: 8px;
              border: 1px solid $color12;
              border-radius: 4px;
              font-size: $content2;
              margin: 0;
              height: 30px;
              width: 100%;
              @include transition(box-shadow 200ms ease-out);

              &.readonly {
                pointer-events: none;
                opacity: 0.6;
              }

              &.invalid,
              &.ng-invalid.ng-dirty {
                border: 1px solid $errorText;
              }

              &:focus {
                @include box-shadow(0px 3px 8px 1px rgba(0, 0, 0, 0.2));
                border-color: $color2;
              }
            }

            input[type='radio'],
            input[type='checkbox'] {
              width: 15px;
              height: 15px;
              margin-right: 5px;
              flex-shrink: 0;

              &:focus {
                @include box-shadow(none);
              }

              & + .label,
              & + label {
                margin: 0;
                display: inline;
              }

              &.invalid,
              &.ng-invalid.ng-dirty {
                &::after {
                  display: block;
                  width: 100%;
                  height: 100%;
                  background: transparent;
                  content: '';
                  border: 1px solid $errorText;
                  box-sizing: border-box;
                }
              }
            }

            input[type='radio'] {
              &.invalid,
              &.ng-invalid.ng-dirty {
                &::after {
                  border-radius: 100%;
                }
              }
            }

            select {
              padding: 0 8px;
              font-size: $content1;
            }

            textarea,
            div[contenteditable='true'] {
              height: 125px;
              resize: none;
            }
          }
        }

        .row {
          // note that flexboxgrid.min.css also targets this class
          margin-bottom: 8px;

          &.align-bottom {
            align-items: flex-end;
          }

          &.nowrap {
            flex-wrap: nowrap;
          }
        }

        .column {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin: 0;

          & > .field {
            margin-right: 0;
          }
        }
      }
    }

    .no-margin {
      margin: 0 !important;
    }

    .no-padding {
      padding: 0 !important;
    }

    .fadeIn {
      @include animation(fadeIn 250ms ease-in);
    }

    .slideInLeft {
      @include animation(slideInLeft 250ms ease-out);
    }

    .slideInLeftAndFade {
      @include animation(slideInLeftAndFade 250ms ease-out);
    }

    .tooltip {
      &.standard {
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;

        .icon {
          width: 20px;
          height: 20px;
        }
      }

      .icon {
        width: 16px;
        height: 16px;
      }
    }

    .btn {
      cursor: pointer;
      @include user-select(none);
      text-align: left;
      @include transition(background-color 0.2s ease-in);
      @include box-sizing(border-box);
      color: $color8;
      padding: 8px 16px;
      font-size: $content1;
      font-weight: 500;
      border-radius: 4px;
      display: inline-flex;
      align-items: center;

      &:active {
        @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
      }

      &.standard-positive {
        background-color: $pacificBlue;

        &:hover {
          background-color: darken($pacificBlue, 15%);
        }
      }

      &.priority-positive {
        background-color: $turtleGreen;

        &:hover {
          background-color: darken($turtleGreen, 15%);
        }
      }

      &.standard-active {
        background-color: $color4;

        &:hover {
          background-color: darken($color4, 15%);
        }
      }

      &.standard-negative {
        background-color: $color1;

        &:hover {
          background-color: lighten($color1, 15%);
        }
      }

      &.alert {
        background-color: $errorText;

        &:hover {
          background-color: darken($errorText, 15%);
        }
      }

      &.no-bkg {
        color: $pacificBlue;
        background-color: transparent;

        &:active {
          box-shadow: none;
        }

        &:hover {
          color: darken($pacificBlue, 15%);

          .icon {
            svg {
              polygon,
              path {
                fill: darken($pacificBlue, 15%);
              }
            }
          }
        }

        .icon,
        & > svg-helper,
        ml-icon {
          fill: $pacificBlue;

          svg {
            polygon,
            path {
              fill: $pacificBlue;
            }
          }
        }
      }

      &.small {
        font-size: $content2;
        padding: 4px 8px;

        .icon,
        & > svg-helper {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }

      &.inactive {
        background-color: $color13;
      }

      &.disabled,
      &[disabled] {
        pointer-events: none;
        background-color: $color13;
      }

      &.quick {
        width: 30px;
        min-width: 30px;
        height: 30px;
        padding: 7px;
        justify-content: center;
        flex-shrink: 0;

        .icon,
        & > svg-helper,
        ml-icon {
          width: 16px;
          height: 16px;
          margin: 0;
        }
      }

      &.file-input,
      &.external-link {
        position: relative;

        input,
        a {
          @include absolute(top 0 bottom 0 left 0 right 0);
          opacity: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }

      &.file-input {
        margin: auto;
      }

      &.margin-right {
        margin-right: 8px;
      }

      &.nowrap {
        white-space: nowrap;
      }

      .icon,
      & > svg-helper,
      ml-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        flex-shrink: 0;
        fill: $color8;

        &.use-stroke {
          svg {
            display: block;

            path,
            polyline,
            polygon,
            line {
              stroke: $color8;
            }
          }
        }

        svg {
          display: block;
          width: 100%;
          height: 100%;

          path,
          polyline,
          polygon,
          rect,
          circle {
            fill: $color8;
          }
        }
      }

      label {
        cursor: inherit;
      }
    }

    table.standard {
      width: 100%;
      max-height: 90%;
      overflow-y: auto;
      border-radius: 10px;
      overflow: hidden;
      text-align: left;
      border-spacing: 0;
      position: relative;
      box-shadow: 0 4px 10px $color3;

      .loading-bars {
        @include align-horizontally();
        top: 100px;
      }

      .clickable {
        cursor: pointer;
      }

      & > thead {
        background-color: $color1;
        color: $color8;
        font-size: $content1;

        & > tr > th {
          padding: 17px;
          @include user-select(none);
          font-weight: normal;
          white-space: nowrap;

          &:first-child {
            border-top-left-radius: 10px;
          }

          &:last-child {
            border-top-right-radius: 10px;
          }

          &.clickable {
            @include transition(background-color 175ms ease-in);

            &:hover {
              background-color: lighten($color1, 15%);
            }
          }

          .label {
            display: inline-block;
            margin-right: 8px;
          }

          .icon {
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: bottom;

            &.arrow {
              &.down {
                @include transform(rotate(180deg));
              }
            }
          }
        }
      }

      & > tbody {
        background-color: $color8;
        color: $color2;
        font-size: $content2;

        & > tr {
          border-spacing: 0;
          @include animation(fadeIn 300ms ease-in);
          @include transition(background-color 150ms ease-in);

          &:hover {
            background-color: $color8;
          }

          &:last-child {
            & > td {
              &:first-child {
                border-bottom-left-radius: 10px;
              }

              &:last-child {
                border-bottom-right-radius: 10px;
              }
            }
          }

          .expander {
            margin-right: 10px;
            cursor: pointer;

            .icon {
              width: 20px;
              height: 20px;
              @include transform(rotate(0deg));
              @include transition(transform 300ms ease-in-out);
            }

            &.isExpanded {
              .icon,
              ml-icon {
                @include transform(rotate(90deg));
              }
            }
          }

          & > td {
            padding: 8px 12px;
            position: relative;
            border-left: 1px dotted $color3;
            border-bottom: 2px solid $color3;
            word-break: break-all;

            &:last-child {
              border-right: 1px dotted $color3;
            }

            &.skinny {
              width: 105px;
            }

            &.no-break {
              word-break: normal;
            }

            &.badge-text {
              .badge {
                margin-right: 8px;
              }
            }

            &.fat {
              white-space: nowrap;

              input {
                width: 70px;
                vertical-align: top;
                margin-right: 10px;
              }

              .btn:first-of-type {
                margin-right: 8px;
              }

              .message {
                display: inline-flex;
                align-items: center;
                justify-content: center;

                .icon {
                  width: 25px;
                  height: 25px;
                  margin-right: 10px;

                  svg path {
                    fill: $color3;
                  }
                }
              }
            }

            &.nowrap {
              white-space: nowrap;

              .btn {
                margin-right: 8px;

                &:last-child {
                  margin: 0;
                }
              }
            }

            &.left-align {
              text-align: left;
            }

            &.right-align {
              text-align: right;
            }

            &.center-align {
              text-align: center;
            }

            &.clickable {
              font-weight: bold;

              &:hover {
                text-decoration: underline;
              }
            }

            &.editable {
              @include transition(background-color 200ms ease-out);

              &:hover {
                background-color: $rowcolor2;

                .icon {
                  opacity: 1;
                }
              }

              &.editing {
                .icon {
                  opacity: 1;
                }
              }

              .field-edit-container {
                display: flex;
                align-items: center;

                .edit-icons {
                  min-width: 80px;
                }
              }

              .icon {
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin: 0 10px;
                opacity: 0;
                flex-shrink: 0;
                @include transition(opacity 200ms ease-out);

                &.active {
                  svg path {
                    fill: $pacificBlue;
                  }

                  &:hover {
                    svg path {
                      fill: darken($pacificBlue, 15%);
                    }
                  }
                }

                svg path {
                  fill: $color3;
                  @include transition(fill 250ms ease-out);
                }
              }

              input[type='text'] {
                width: 300px;
              }

              textarea {
                width: auto;
                height: auto;
                max-width: 250px;
                flex: 1 1;
              }
            }

            .icon,
            .label {
              display: inline-block;
              vertical-align: middle;
            }

            .button-group {
              display: inline-block;
              margin: auto;

              .btn:first-child {
                margin-right: 8px;
              }
            }

            .badge {
              width: 35px;
              height: 35px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background-color: transparent;
              font-size: $content1;
              font-weight: bold;
              border-radius: 7px;
              border: 2px solid;
              margin: 0;

              &.gold {
                border-color: $color4;
                color: $color4;
              }

              &.blue {
                border-color: $pacificBlue;
                color: $pacificBlue;
              }

              &.gray {
                border-color: $color3;
                color: $color3;
              }

              &.green {
                border-color: $turtleGreen;
                color: $turtleGreen;
              }
            }

            .button-overlay {
              @include absolute(top 0 bottom 0 left 0 right 0);
              background-color: rgba($color1, 0.9);
              @include animation(fadeIn 200ms ease-in);
              display: flex;
              align-items: center;
              justify-content: space-between;

              .btn {
                flex-direction: column;
                height: 100%;
                width: 50%;
                font-size: $content3;
                border: none;
                background-color: transparent;
                @include transition(background-color 150ms ease-in);
                padding: 0;
                justify-content: center;

                &:hover {
                  background-color: rgba($color1, 0.5);
                }

                .icon {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }

    .scrollable-table-container {
      max-height: 500px;
      overflow-y: auto;
      @include styled-scrollbar();
      padding: 0;
      box-shadow: 0 4px 10px #95989a;
      border-radius: 10px;

      table.standard {
        overflow: unset;
        box-shadow: none;

        thead th {
          position: sticky;
          top: 0;
          z-index: 2;
          background-color: $color1;
          color: #fff;
          border-radius: unset;
        }
      }
    }

    .white-space-nowrap {
      white-space: nowrap;
    }

    .loading-indicator {
      background-color: rgba($color1, 0.85);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      border-radius: 8px;

      svg-helper {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        width: 35%;
        height: 35%;
        @include animation(rotator 2s linear infinite);
      }
    }
  }
}

confirm-modal {
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    .icon {
      width: 30px;
      height: 30px;
      margin: auto;

      svg {
        path,
        rect {
          fill: $color1;
        }
      }
    }

    .label {
      margin-left: 10px;
      font-size: $sub-head1;
      font-weight: bold;
    }
  }

  .message {
    font-size: $sub-head2;
    text-align: center;

    & + .message {
      margin-top: 1rem;
    }

    p {
      margin: 0;
    }

    span {
      display: block;
    }

    & > * + * {
      margin-top: 1rem;
    }
  }

  .btn {
    margin-top: 1rem;
  }
}

.modal-backdrop {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    width: auto;
    position: relative;
    top: auto;
    left: auto;
    @include transform(none);
    max-width: 600px;
    text-align: center;
    font-size: $content1;

    .btn {
      margin: 1rem 0.5rem 0;
    }

    .icon.large {
      width: 60px;
      height: 60px;
      margin: 0 auto 10px;

      svg {
        path,
        rect {
          fill: $color4;
        }
      }

      &.priority-positive {
        svg {
          path,
          rect {
            fill: $turtleGreen;
          }
        }
      }

      &.priority-error {
        svg {
          path,
          rect {
            fill: $errorText;
          }
        }
      }
    }

    .title {
      font-size: $headline;
      font-weight: bold;
    }

    p {
      line-height: 20px;
    }

    .left-align {
      text-align: left;
    }

    .caps {
      text-transform: uppercase;
    }
  }
}

body {
  .mat-mdc-tooltip {
    background-color: rgba($color1, 0.85);
    color: $color8;
    border-radius: 4px;
    font-size: $content2;
    font-family: 'Lato', sans-serif;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
    overflow: visible;
    white-space: pre-line;

    &.highlighted {
      --mdc-plain-tooltip-container-color: $color8;
      --mdc-plain-tooltip-supporting-text-color: $color1;
      background-color: $color8;
      color: $color1;
      border: 2px solid $color4;
    }

    &.error {
      background-color: $color8;
      color: $color1;
      border: 2px solid $errorText;
    }

    &.up-arrow {
      &:before {
        @include align-horizontally();
        bottom: 100%;
        width: 0;
        height: 0;
        border: solid transparent;
        content: '';
      }

      &:before {
        border-bottom-color: rgba($color1, 0.85);
        border-width: 9px;
      }
    }

    &.left-arrow {
      &:before {
        @include align-vertically();
        right: 100%;
        width: 0;
        height: 0;
        border: solid transparent;
        content: '';
      }

      &:before {
        border-right-color: rgba($color1, 0.85);
        border-width: 7px;
      }
    }
  }

  .mat-mdc-select-panel {
    .mat-accent .mat-pseudo-checkbox-checked,
    .mat-accent .mat-pseudo-checkbox-indeterminate,
    .mat-pseudo-checkbox-checked,
    .mat-pseudo-checkbox-indeterminate {
      background-color: $color4;
    }
  }

  .mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
      border-radius: 4px;
    }

    &.success .mdc-snackbar__surface {
      background-color: $turtleGreen;
    }

    &.error .mdc-snackbar__surface {
      background-color: $errorText;
      color: #fff;

      .mat-mdc-snack-bar-action {
        color: #fff;
      }
    }

    &.spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(175, 175, 175);

      .mat-mdc-progress-spinner,
      svg {
        width: 50px !important;
        height: 50px !important;
      }
    }

    .mat-mdc-simple-snack-bar {
      justify-content: center;
    }
  }
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  border: solid 5px transparent;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px rgb(35, 35, 35, 0.35);
  border: solid 5px transparent;
  border-radius: 1rem;
}

@include keyframes(scaleOutRight) {
  0% {
    @include transform(scaleX(0));
  }

  100% {
    @include transform(scaleX(1));
  }
}
